<template>
  <div class="overlay">
    <div class="modal">
      <h2 class="modal__notice"></h2>
      <a @click.prevent="hideModal" href="#" class="btn btn--close modal__close">
        <div class="visually-hidden">Close</div>
      </a>
      <div class="modal__title" v-html="segment.title"></div>
      <div class="modal__description">
        <span v-html="segment.description"></span>
        <span>Other layout options available. Images for illustration purposes only.</span>
      </div>
      <div class="modal__image">
        <img :src="segment.presentation" :alt="segment.title">
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['seg'],
    data() {
      return {
        segment: this.seg
      }
    },
    methods: {
      hideModal() {
        this.$emit('closeSegmentModal');
      }
    }
  }
</script>
