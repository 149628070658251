<template>
  <div class="vue-template">
    <header class="page-header">
      <a href="https://www.pickeringshire.co.uk/" data-gtm-a-tool-logo-to-homepage>
        <img src="~/images/logo.svg" alt="Pickerings logo - link to pickerings main website">
      </a>
    </header>
    <main class="page-content">
      <aside class="sidebar">
        <div class="building__select">
          <label for="building-select">Choose a unit size</label>
          <select v-model="unit_size" name="building-select">
            <option v-for="opt in unit_size_options"
                    :key="opt.value"
                    :value="opt.value"
                    v-html="opt.label"
                    :selected="opt.value === unit_size"></option>
          </select>
        </div>
        <div class="building__nav">
          <img v-if="ghostSet"
              class="ghost"
              :src="ghost_src"
              :alt="ghost_alt"
              :style="`margin-top:-110px; margin-left: -45px; position: fixed; left: ${ghost_client_x}px; top: ${ghost_client_y}px`"/>
          <p>Choose a unit type</p>
          <div class="building__overflow">
            <div class="building__wrapper">
              <div class="building"
                  v-for="segment in segments"
                  :key="segment.id">
                <a @click.prevent="viewSegmentDetails(segment.id)" href="#" class="building__details btn">
                  <div class="visually-hidden">View building details</div>
                </a>
                <picture draggable="true"
                        @dragstart="startDrag($event, segment)">
                  <img :src="segment.sprite"
                      class="segment"
                      alt="Pickerings modular building image"
                      @touchstart.prevent="touchStart($event, segment)"
                      @touchmove.prevent="touchMove($event)"
                      @touchend.prevent="touchEnd($event, segment)">
                </picture>
                <div class="building__divider"></div>
                <div class="building__title" v-html="segment.title"></div>
              </div>
            </div>
          </div>
        </div>
      </aside>
      <section class="main-content">
        <div class="floor">
          <div class="floors">
            <a v-for="floor in floors"
              :key="floor.level"
              href="#"
              class="btn btn--secondary"
              :class="{'btn--active': isCurrentFloor(floor.level) }"
              @click.prevent="setFloor(floor.level)">Floor {{ floor.level }}</a>
          </div>
          <div class="floor-controls">
            <a v-if="canAddFloor" @click.prevent="addFloor" href="#" class="btn btn--rounded floor-controls__add">Add Floor</a>
            <a v-else @click.prevent href="#" class="btn btn--rounded floor-controls__add disabled">Add Floor</a>
            <span class="toggletip">
              <button type="button"
                      class="toggletip__button btn btn--brand btn--override"
                      aria-label="more info"
                      @click="toggleFloorTooltip"></button>
              <span role="status" class="tooltip__message display-on-right">
                <div v-if="showFloorTooltip" class="toggletip__bubble">
                  <h4>Please contact us for 3 story requests.</h4>
                </div>
              </span>
            </span>

            <a v-if="canClearFloor" @click.prevent="clearFloor" href="#" class="btn btn--rounded floor-controls__clear">Clear Floor</a>
            <a v-else @click.prevent href="#" class="btn btn--rounded floor-controls__clear disabled">Clear Floor</a>
            <a v-if="canRemoveFloor" @click.prevent="removeFloor" href="#" class="btn btn--rounded floor-controls__remove">Remove Floor</a>
            <a v-else @click.prevent href="#" class="btn btn--rounded floor-controls__remove disabled">Remove Floor</a>
          </div>
        </div>
        <div class="canvas">
          <div class="canvas__overflow">
            <div class="box__wrapper">
              <div class="box__padding">
                <div class="box__grid" :style="`height: ${zoom_levels[selected_zoom_level][0]}px; width: ${zoom_levels[selected_zoom_level][1]}px;`">
                  <div v-for="cell in currentFloorGrid"
                      @drop.prevent="onDrop($event, cell)"
                      :key="`${cell.xcoord}x${cell.ycoord}`"
                      class="box"
                      @dragenter.prevent="setDragEnter($event, cell)"
                      @dragleave.prevent="setDragLeave($event, cell)"
                      @dragover.prevent
                      :class="{box__placed: cell.contains, box__silhouette: segmentUnder(cell)}"
                      :style="styleUnder(cell)"
                      :data-x="cell.xcoord"
                      :data-y="cell.ycoord"
                      :data-f="cell.floor"
                      :id="`${cell.xcoord}${cell.ycoord}${cell.floor}`">
                    <div style="pointer-events: none;" v-if="cell.hover_state">
                      <picture style="pointer-events: none;" class="box__image">
                        <img class="segment"
                            style="pointer-events: none;"
                            src="~images/box-guide.svg" alt="">
                      </picture>
                    </div>
                    <div v-else>
                      <a href="#"
                        v-if="cell.contains"
                        class="box__delete btn"
                        @click.prevent="removeCellContent(cell)">
                        <div class="visually-hidden">Delete building</div>
                      </a>
                      <picture v-if="cell.contains"
                              draggable="true"
                              @dragstart="startDrag($event, cell.contains, cell)"
                              class="box__image">
                        <img :src="cell.contains.sprite"
                            class="segment"
                            @touchstart.prevent="touchStart($event, cell.contains)"
                            @touchmove.prevent="touchMove($event)"
                            @touchend.prevent="touchEnd($event, cell.contains, cell)">
                      </picture>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="canvas__nav">
            <a @click.prevent="zoomIn" href="#" class="btn btn--square canvas__zoom canvas__zoom-in">
              <div class="visually-hidden">Zoom in</div>
            </a>
            <a @click.prevent="zoomOut" href="#" class="btn btn--square canvas__zoom canvas__zoom-out">
              <div class="visually-hidden">Zoom out</div>
            </a>
          </div>
          <div class="canvas__dimensions">Total <span v-html="totalSquareFeet"></span> square feet</div>
          <a @click.prevent="showCompletionForm" href="#" class="btn btn--brand" data-gtm-btn-tool-complete-my-design>Complete my design</a>
        </div>
      </section>
    </main>
    <section class="completion-form" :class="{ active: form_is_active }">
      <div class="completion-form__wrapper">
        <a href="/" class="btn btn--close" @click.prevent="showCompletionForm">
          <div class="visually-hidden">Close form sidebar</div>
        </a>
        <div v-if="message_sent" class="message">
          <div class="message--success">
            <h2>Thank you for submitting your design. One of our sales team experts will be in touch with you to discuss your requirements shortly. <a @click.prevent="resetCanvas" href="/">Reset design</a></h2>
          </div>
        </div>
        <form v-else action="">
          <fieldset>
            <legend class="completion-form__title">Complete my design</legend>
            <label :class="{errors: errors.first_name}" for="first_name" class="completion-form__label">First Name</label>
            <input v-model="submission_first_name" id="first_name" type="text" name="first_name">
            <template v-if="errors.first_name">
              <span v-for="error in errors.first_name"
                    :key="error"
                    v-html="error"
                    class="form-error"></span>
            </template>

            <label :class="{errors: errors.last_name}" for="last_name" class="completion-form__label">Last Name</label>
            <input v-model="submission_last_name" id="last_name" type="text" name="last_name">
            <template v-if="errors.last_name">
              <span v-for="error in errors.last_name"
                    :key="error"
                    v-html="error"
                    class="form-error"></span>
            </template>

            <label :class="{errors: errors.company_name}" for="companyname" class="completion-form__label">Company Name</label>
            <input v-model="submission_company_name" id="companyname" type="text" name="companyname">
            <template v-if="errors.company_name">
              <span v-for="error in errors.company_name"
                    :key="error"
                    v-html="error"
                    class="form-error"></span>
            </template>

            <label :class="{errors: errors.email}" for="email" class="completion-form__label">Email</label>
            <input v-model="submission_email" id="email" type="email" name="email">
            <template v-if="errors.email">
              <span v-for="error in errors.email"
                    :key="error"
                    v-html="error"
                    class="form-error"></span>
            </template>

            <label :class="{errors: errors.telephone}" for="telephone" class="completion-form__label">Telephone</label>
            <input v-model="submission_telephone" id="telephone" type="tel" name="telephone">
            <template v-if="errors.telephone">
              <span v-for="error in errors.telephone"
                    :key="error"
                    v-html="error"
                    class="form-error"></span>
            </template>

            <label :class="{errors: errors.postcode}" for="postcode" class="completion-form__label">Postcode</label>
            <input v-model="submission_postcode" id="postcode" type="tel" name="postcode">
            <template v-if="errors.postcode">
              <span v-for="error in errors.postcode"
                    :key="error"
                    v-html="error"
                    class="form-error"></span>
            </template>
          </fieldset>

          <fieldset>
            <legend class="completion-form__label completion-form__legend">For hire or for purchase</legend>
            <div class="field--checkbox">
              <label :class="{errors: errors.hire_or_purchase}">
                <input v-model="submission_hire_or_purchase" type="radio" value="hire" name="radio">Hire
                <div class="checkbox__indicator"></div>
              </label>
            </div>
            <div class="field--checkbox">
              <label :class="{errors: errors.hire_or_purchase}">
                <input v-model="submission_hire_or_purchase" type="radio" value="purchase" name="radio">Purchase
                <div class="checkbox__indicator"></div>
              </label>
            </div>
            <template v-if="errors.hire_or_purchase">
              <span v-for="error in errors.hire_or_purchase"
                    :key="error"
                    v-html="error"
                    class="form-error"></span>
            </template>
            <template v-if="submission_hire_or_purchase === 'hire'">
              <label :class="{errors: errors.hire_period}" for="hire_period" class="completion-form__label">Hire period</label>
              <input v-model="submission_hire_period" type="string" name="hire_period">
              <template v-if="errors.hire_period">
                <span v-for="error in errors.hire_period"
                      :key="error"
                      v-html="error"
                      class="form-error"></span>
              </template>
            </template>
          </fieldset>

          <fieldset>
            <label :class="{errors: errors.comments}" for="comments" class="completion-form__label">Comments</label>
            <textarea v-model="submission_comments" id="comments" name="comments" cols="30" rows="10"></textarea>
            <template v-if="errors.comments">
              <span v-for="error in errors.comments"
                    :key="error"
                    v-html="error"
                    class="form-error"></span>
            </template>
          </fieldset>
          <input @click.prevent="sendEnquiry"
                 class="btn btn--brand"
                 type="submit"
                 value="Submit"
                 data-gtm-btn-tool-submit>
        </form>
      </div>
    </section>
    <segment-detail-modal @closeSegmentModal="hideSegmentInfoModal" v-for="segment in segments" :key="segment.id" v-show="segment.detail_modal_visible" :seg="segment"></segment-detail-modal>
    <div class="device-notice overlay">
      <div class="modal">
        <div class="modal__description">Your screen dimensions are too small to display this app correctly. Please try again on a screen that is at least 960px wide and 700px tall.</div>
      </div>
    </div>
  </div>
</template>

<script>
import SegmentDetailModal from './segment_detail_modal.vue';
import UtmCookies from '../javascript/utm-cookies';

export default {
  components: {
    SegmentDetailModal,
  },
  data() {
    return {
      utm_cookies: null,
      current_floor: 1,
      errors: {},
      floors: [{ level: 1 }],
      form_is_active: false,
      ghost_alt: null,
      ghost_client_x: null,
      ghost_client_y: null,
      ghost_src: null,
      grid: [
        { floor: 1, hover_state: false, xcoord: 1, ycoord: 1, contains: null },
        { floor: 1, hover_state: false, xcoord: 2, ycoord: 1, contains: null },
        { floor: 1, hover_state: false, xcoord: 3, ycoord: 1, contains: null },
        { floor: 1, hover_state: false, xcoord: 4, ycoord: 1, contains: null },
        { floor: 1, hover_state: false, xcoord: 5, ycoord: 1, contains: null },
        { floor: 1, hover_state: false, xcoord: 6, ycoord: 1, contains: null },
        { floor: 1, hover_state: false, xcoord: 7, ycoord: 1, contains: null },
        { floor: 1, hover_state: false, xcoord: 8, ycoord: 1, contains: null },
        { floor: 1, hover_state: false, xcoord: 9, ycoord: 1, contains: null },
        { floor: 1, hover_state: false, xcoord: 10, ycoord: 1, contains: null },
        { floor: 1, hover_state: false, xcoord: 11, ycoord: 1, contains: null },
        { floor: 1, hover_state: false, xcoord: 1, ycoord: 2, contains: null },
        { floor: 1, hover_state: false, xcoord: 2, ycoord: 2, contains: null },
        { floor: 1, hover_state: false, xcoord: 3, ycoord: 2, contains: null },
        { floor: 1, hover_state: false, xcoord: 4, ycoord: 2, contains: null },
        { floor: 1, hover_state: false, xcoord: 5, ycoord: 2, contains: null },
        { floor: 1, hover_state: false, xcoord: 6, ycoord: 2, contains: null },
        { floor: 1, hover_state: false, xcoord: 7, ycoord: 2, contains: null },
        { floor: 1, hover_state: false, xcoord: 8, ycoord: 2, contains: null },
        { floor: 1, hover_state: false, xcoord: 9, ycoord: 2, contains: null },
        { floor: 1, hover_state: false, xcoord: 10, ycoord: 2, contains: null },
        { floor: 1, hover_state: false, xcoord: 11, ycoord: 2, contains: null },
        { floor: 1, hover_state: false, xcoord: 1, ycoord: 3, contains: null },
        { floor: 1, hover_state: false, xcoord: 2, ycoord: 3, contains: null },
        { floor: 1, hover_state: false, xcoord: 3, ycoord: 3, contains: null },
        { floor: 1, hover_state: false, xcoord: 4, ycoord: 3, contains: null },
        { floor: 1, hover_state: false, xcoord: 5, ycoord: 3, contains: null },
        { floor: 1, hover_state: false, xcoord: 6, ycoord: 3, contains: null },
        { floor: 1, hover_state: false, xcoord: 7, ycoord: 3, contains: null },
        { floor: 1, hover_state: false, xcoord: 8, ycoord: 3, contains: null },
        { floor: 1, hover_state: false, xcoord: 9, ycoord: 3, contains: null },
        { floor: 1, hover_state: false, xcoord: 11, ycoord: 3, contains: null },
        { floor: 1, hover_state: false, xcoord: 10, ycoord: 3, contains: null },
        { floor: 2, hover_state: false, xcoord: 1, ycoord: 1, contains: null },
        { floor: 2, hover_state: false, xcoord: 2, ycoord: 1, contains: null },
        { floor: 2, hover_state: false, xcoord: 3, ycoord: 1, contains: null },
        { floor: 2, hover_state: false, xcoord: 4, ycoord: 1, contains: null },
        { floor: 2, hover_state: false, xcoord: 5, ycoord: 1, contains: null },
        { floor: 2, hover_state: false, xcoord: 6, ycoord: 1, contains: null },
        { floor: 2, hover_state: false, xcoord: 7, ycoord: 1, contains: null },
        { floor: 2, hover_state: false, xcoord: 8, ycoord: 1, contains: null },
        { floor: 2, hover_state: false, xcoord: 9, ycoord: 1, contains: null },
        { floor: 2, hover_state: false, xcoord: 10, ycoord: 1, contains: null },
        { floor: 2, hover_state: false, xcoord: 11, ycoord: 1, contains: null },
        { floor: 2, hover_state: false, xcoord: 1, ycoord: 2, contains: null },
        { floor: 2, hover_state: false, xcoord: 2, ycoord: 2, contains: null },
        { floor: 2, hover_state: false, xcoord: 3, ycoord: 2, contains: null },
        { floor: 2, hover_state: false, xcoord: 4, ycoord: 2, contains: null },
        { floor: 2, hover_state: false, xcoord: 5, ycoord: 2, contains: null },
        { floor: 2, hover_state: false, xcoord: 6, ycoord: 2, contains: null },
        { floor: 2, hover_state: false, xcoord: 7, ycoord: 2, contains: null },
        { floor: 2, hover_state: false, xcoord: 8, ycoord: 2, contains: null },
        { floor: 2, hover_state: false, xcoord: 9, ycoord: 2, contains: null },
        { floor: 2, hover_state: false, xcoord: 10, ycoord: 2, contains: null },
        { floor: 2, hover_state: false, xcoord: 11, ycoord: 2, contains: null },
        { floor: 2, hover_state: false, xcoord: 1, ycoord: 3, contains: null },
        { floor: 2, hover_state: false, xcoord: 2, ycoord: 3, contains: null },
        { floor: 2, hover_state: false, xcoord: 3, ycoord: 3, contains: null },
        { floor: 2, hover_state: false, xcoord: 4, ycoord: 3, contains: null },
        { floor: 2, hover_state: false, xcoord: 5, ycoord: 3, contains: null },
        { floor: 2, hover_state: false, xcoord: 6, ycoord: 3, contains: null },
        { floor: 2, hover_state: false, xcoord: 7, ycoord: 3, contains: null },
        { floor: 2, hover_state: false, xcoord: 8, ycoord: 3, contains: null },
        { floor: 2, hover_state: false, xcoord: 9, ycoord: 3, contains: null },
        { floor: 2, hover_state: false, xcoord: 10, ycoord: 3, contains: null },
        { floor: 2, hover_state: false, xcoord: 11, ycoord: 3, contains: null },
      ],
      hovered_element: null,
      message_sent: false,
      targeted_element: null,
      segments: [],
      selected_zoom_level: 1,
      showFloorTooltip: false,
      showDimensionTooltip: false,
      submission_first_name: '',
      submission_last_name: '',
      submission_company_name: '',
      submission_email: '',
      submission_telephone: '',
      submission_postcode: '',
      submission_hire_or_purchase: '',
      submission_hire_period: '',
      submission_comments: '',
      unit_size: 300,
      unit_size_options: [],
      zoom_levels: [[1320, 1980], [660, 990]],
    }
  },
  methods: {
    addFloor() {
      const floor = this.floorFactory();
      this.floors.push(floor);
      this.setFloor(floor.level);
    },
    clearFloor() {
      this.currentFloorGrid.map(cell => { cell.contains = null });
    },
    floorFactory() {
      return { 'level': this.floors.length + 1 }
    },
    hideSegmentInfoModal() {
      this.segments.map(segment => { segment.detail_modal_visible = false })
    },
    isCurrentFloor(floor) {
      return floor === this.current_floor;
    },
    onDrop(e, cell) {
      if (e.dataTransfer.getData('prevX')) {
        const prevX = parseInt(e.dataTransfer.getData('prevX'));
        const prevY = parseInt(e.dataTransfer.getData('prevY'));
        const prevF = parseInt(e.dataTransfer.getData('prevF'));

        if (cell.xcoord === prevX &&
            cell.ycoord === prevY &&
            cell.floor === prevF) return;

        const prevCell = this.grid.find(function(c) {
          return c.xcoord === prevX &&
                 c.ycoord === prevY &&
                 c.floor === prevF
        });

        cell.contains = prevCell.contains

        prevCell.contains = null;
      } else {
        const segmentId = e.dataTransfer.getData('segmentID');
        const segCopy = this.segments.find(segment => { return segment.id === parseInt(segmentId) });
        cell.contains = segCopy;
      }

      cell.hover_state = false;
    },
    removeCellContent(cell) {
      cell.contains = null;
    },
    removeFloor() {
      this.clearFloor();
      const newFloors = this.floors.filter(floor => { return floor.level !== this.current_floor });

      if (newFloors.length === 0) return;

      this.floors = newFloors;
      this.current_floor = this.floors[this.floors.length - 1]['level'];
    },
    resetCanvas() {
      this.grid.map(cell => { cell.contains = null });
      this.message_sent = false;
      this.submission_first_name = '';
      this.submission_last_name = '';
      this.submission_company_name = '';
      this.submission_email = '';
      this.submission_telephone = '';
      this.submission_postcode = '';
      this.submission_hire_or_purchase = '';
      this.submission_hire_period = '';
      this.submission_comments = '';
      this.errors = {},
      this.current_floor = 1,
      this.floors = [{ level: 1 }]
    },
    resetHoverState() {
      this.currentFloorGrid.map((cell) => { cell.hover_state = false });
    },
    segmentUnder(cell) {
      if (this.current_floor === 1) return false;

      return this.grid.find(c => {
        return c.xcoord === cell.xcoord &&
               c.ycoord === cell.ycoord &&
               c.contains &&
               c.floor < cell.floor
      });
    },
    segmentUnderImage(cell) {
      if (this.current_floor === 1) return false;

      const cellUnder = this.grid.find(c => {
        return c.xcoord === cell.xcoord &&
               c.ycoord === cell.ycoord &&
               c.contains &&
               c.floor < cell.floor
      });

      if (cellUnder && cellUnder.contains) { return cellUnder.contains.sprite_opacity }
    },
    sendEnquiry() {

      const token = document.querySelector('body').dataset['tkn'];

      this.errors = {};

      this.axios.post('/form_submissions.json', {
        authenticity_token: token,
        form_submission: {
          first_name: this.submission_first_name,
          last_name: this.submission_last_name,
          company_name: this.submission_company_name,
          email: this.submission_email,
          telephone: this.submission_telephone,
          postcode: this.submission_postcode,
          hire_or_purchase: this.submission_hire_or_purchase,
          hire_period: this.submission_hire_period,
          comments: this.submission_comments,
          schema: this.postJSON,
          unit_size: this.unit_size,
          utm: this.utm_cookies,
        }
      }).then((response) => {
        this.errors = response.data.errors
        if (response.status === 204) {
          this.thankyou();
        }
      })
    },
    setDragEnter(e, cell) {
      if (cell.contains) return;

      cell.hover_state = true;
    },
    setDragLeave(e, cell) {
      if (cell.contains) return;

      cell.hover_state = false;
    },
    setFloor(floor) {
      this.current_floor = floor;
    },
    setGhost(segment=null) {
      if (segment) {
        this.ghost_alt = segment.title;
        this.ghost_src = segment.sprite;
      } else {
        this.ghost_alt = null;
        this.ghost_src = null;
        this.ghost_client_x = null;
        this.ghost_client_y = null;
      }
    },
    showCompletionForm() {
      this.form_is_active = !this.form_is_active;
    },
    startDrag(e, segment, cell=null) {
      e.dataTransfer.dropEffect = 'move';
      e.dataTransfer.effectAllowed = 'copy';
      e.dataTransfer.setData('segmentID', segment.id);
      if (cell) {
        e.dataTransfer.setData('prevX', cell.xcoord);
        e.dataTransfer.setData('prevY', cell.ycoord);
        e.dataTransfer.setData('prevF', cell.floor);
      }
    },
    resetTooltips() {
      this.showDimensionTooltip = false;
      this.showFloorTooltip = false;
    },
    styleUnder(cell) {
      if (!(this.segmentUnder(cell))) return;

      return `background-image: url('${this.segmentUnderImage(cell)}')`
    },
    thankyou() {
      this.message_sent = true;
    },
    toggleDimensionTooltip() {
      const state = this.showDimensionTooltip;
      this.resetTooltips();
      this.showDimensionTooltip = !state;
    },
    toggleFloorTooltip() {
      const state = this.showFloorTooltip;
      this.resetTooltips();
      this.showFloorTooltip = !state;
    },
    touchStart(e, segment) {
      this.setGhost(segment);
    },
    touchMove(e) {
      this.ghost_client_x = e.changedTouches[0].clientX;
      this.ghost_client_y = e.changedTouches[0].clientY;

      const el = document.elementFromPoint(this.ghost_client_x, this.ghost_client_y);

      if (this.hovered_element === el.id) return;

      if (!(el.classList.contains('box') && el.tagName === 'DIV')) {
        if (el.classList.contains('segment') && el.tagName === 'IMG') {
          this.resetHoverState();
        }

        return;
      }

      this.hovered_element = el.id;

      let hoverCell = this.currentFloorGrid.find(cell => {
        return cell.xcoord === parseInt(el.dataset.x)
               && cell.ycoord === parseInt(el.dataset.y)
               && cell.floor === parseInt(el.dataset.f)
      });

      this.resetHoverState();

      hoverCell.hover_state = true;
    },
    touchEnd(e, segment, cell=null) {
      const el = document.elementFromPoint(
        e.changedTouches[0].clientX,
        e.changedTouches[0].clientY
      );

      this.setGhost();
      this.resetHoverState();

      const isDropDiv = (el.tagName === 'DIV' && el.classList && el.classList.contains('box'));
      const isDropImg = (el.tagName === 'IMG' && el.classList && el.classList.contains('segment'));

      if ((!isDropDiv) && (!isDropImg)) return;

      let dropCell = this.currentFloorGrid.find(c => {
        return c.xcoord === parseInt(isDropDiv ? el.dataset.x : el.closest('div.box').dataset.x)
               && c.ycoord === parseInt(isDropDiv ? el.dataset.y : el.closest('div.box').dataset.y)
               && c.floor === parseInt(isDropDiv ? el.dataset.f : el.closest('div.box').dataset.f)
      });

      if (cell &&
          cell.xcoord === dropCell.xcoord &&
          cell.ycoord === dropCell.ycoord &&
          cell.floor === dropCell.floor) return;

      dropCell.contains = segment;

      if (cell) {
        cell.contains = null;
      }
    },
    viewSegmentDetails(id) {
      this.segments.map((seg) => {
        seg.detail_modal_visible = (id === seg.id)
      })
    },
    zoomIn() {
      if (this.selected_zoom_level === 0) return;

      this.selected_zoom_level -= 1;
    },
    zoomOut() {
      if (this.selected_zoom_level === 1) return;

      this.selected_zoom_level += 1;
    },
  },
  computed: {
    canAddFloor() { return this.floors.length < 2 },
    canClearFloor() {
      return this.grid.findIndex(cell => {
        return (this.current_floor < cell.floor && cell.contains)
      }) === -1;
    },
    canRemoveFloor() {
      return this.floors.length > 1 && this.current_floor > 1;
    },
    currentFloorGrid() {
      return this.grid.filter(cell => { return cell.floor === this.current_floor })
    },
    ghostSet() {
      return (this.ghost_src && this.ghost_alt);
    },
    segmentsLoaded() { return this.segments.length > 0 },
    totalSquareFeet() {
      const placements = this.grid.filter(cell => {
        return cell.contains !== null;
      });

      return (placements.length * this.unit_size).toLocaleString(undefined, { minimumFractionDigits: 0 });
    },
    postJSON() {
      return this.grid.filter(cell => {
        return cell.contains
      }).map(cell => {
        return {
          f: cell.floor,
          x: cell.xcoord,
          y: cell.ycoord,
          s: cell.contains.id
        }
      })
    },
  },
  mounted() {
    this.axios.get('/segments.json').then((response) => {
      let newSeg = response.data.segments.map((segment) => {
        segment.detail_modal_visible = false;

        return segment;
      })
      this.segments = newSeg;
      this.unit_size_options = response.data.unit_size_options
    });
    let utmVars = new UtmCookies(window);
    this.utm_cookies = utmVars.getUtmVars();
  }
}
</script>
