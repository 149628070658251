import Cookies from 'js-cookie/dist/js.cookie.min';

class UtmCookies {
  constructor(win) {
    this.win = win;
    if (win.location.search !== '') {
      this.searchString = win.location.search;
      this.saveUtmVars();
    }
  }

  getUtmVars() {
    return Cookies.get('ljcms_utmvars');
  }

  saveUtmVars() {
    Cookies.set('ljcms_utmvars', this.utmVars().join(','));
  }

  searchVars() {
    return this.searchString.replace(/^\?/, '').split('&');
  }

  utmVars() {
    return this.searchVars().filter((e) => e.includes('utm_'));
  }
}

export default UtmCookies;
